/* Nubes y animación */
#clouds {
    padding: 5px 0;
}

.cloud {
    width: 200px;
    height: 60px;
    background: #fff;
    border-radius: 200px;
    position: relative;
    will-change: transform;
}

.cloud:before, .cloud:after {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 100px;
    transform: rotate(30deg);
}

.cloud:before {
    width: 100px;
    height: 80px;
    top: -15px;
    left: 10px;
}

.cloud:after {
    width: 120px;
    height: 120px;
    top: -55px;
    right: 10px;
}

.x1 {
	left: 200px;
	animation: moveclouds 15s linear(0.05 -8.46%, 1.1 47.97%) infinite;
}

.x2 {
    left: 200px;
    transform: scale(0.6);
    opacity: 0.6;
	animation: moveclouds 25s linear(0.03 -17.1%, 1.03 49.27%) infinite;
}

.x3 {
    left: 200px;
    top: 200px;
    transform: scale(0.8);
    opacity: 0.8;
	animation: moveclouds 25s linear(0.03 -17.1%, 1.03 49.27%) infinite;
}

.x4 {
    left: 200px;
    transform: scale(0.75);
    opacity: 0.75;
    /*animation: moveclouds 18s linear(0 0%, 1.01 49.47%) infinite;*/
}

.x5 {
    left: 150px;
    top: 150px;
    transform: scale(0.8);
    opacity: 0.8;
	animation: moveclouds 25s linear(0.03 -17.1%, 1.03 49.27%) infinite;
}

/* Keyframes ajustados para el viewport */
@keyframes moveclouds {
    0% {
        transform: translateX(30vw);
    }
    100% {
        transform: translateX(-100vw);
    }
}

/* Ajustes para pantallas pequeñas */
@media screen and (max-width: 1224px) {
    .cloud {
        width: 100px;
        height: 30px;
    }
    
    .cloud:before {
        width: 50px;
        height: 40px;
    }

    .cloud:after {
        width: 60px;
        height: 60px;
    }

    .x2, .x4 {
        left: 47px;
    }

    @keyframes moveclouds {
        0% {
            transform: translateX(70vw);
        }
        100% {
            transform: translateX(-100vw);
        }
    }
}

@media screen and (max-width: 730px) {
    .cloud {
        left: calc(80vw - 120px); /* Asegura que la nube no salga del viewport */
    }

    @keyframes moveclouds {
        0% {
            transform: translateX(80vw);
        }
        100% {
            transform: translateX(-120vw);
        }
    }
}


@media screen and (max-width: 830px) {
    .cloud {
        left: calc(40vw - 120px); /* Asegura que la nube no salga del viewport */
    }

    @keyframes moveclouds {
        0% {
            transform: translateX(80vw);
        }
        100% {
            transform: translateX(-120vw);
        }
    }
}