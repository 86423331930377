@import url('https://fonts.googleapis.com/css?family=Poppins:900i');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinea al inicio del contenedor (top) */
  height: 10vh; /* Ocupa toda la altura de la pantalla */
}

.cta {
  display: flex;
  justify-content: center; /* Centra el texto dentro del botón */
  align-items: center; /* Centra el texto verticalmente dentro del botón */
  padding: 10px 45px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem; /* Ajustado para mejor visibilidad en pantallas más pequeñas */
  color: white;
  background: #f26705;
  transition: background 0.3s, box-shadow 0.3s; /* Ajuste de transición para cambios más suaves */
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
}

.cta:hover {
  background: #d15a04; /* Cambia el color en hover para mejor feedback visual */
}

.cta:focus {
  outline: none; 
}

/* Media Query for Mobile Responsiveness */
@media screen and (max-width: 425px) {
  .cta {
    font-size: 1.5rem; /* Reduce el tamaño de la fuente para pantallas pequeñas */
    padding: 8px 30px; /* Ajusta el padding para que el botón no sea demasiado grande */
  }
}

/* Media Query for Very Small Screens */
@media screen and (max-width: 375px) {
  .cta {
    font-size: 1.2rem; /* Reduce aún más el tamaño de la fuente para pantallas muy pequeñas */
    padding: 6px 20px; /* Ajusta el padding para pantallas muy pequeñas */
  }
}