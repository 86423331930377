#birds {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .bird {
    position: absolute;
    width: 150px;
    height: 150px;
    background: url('../../assets/logos/bird-silhouette.svg') no-repeat center center;
    background-size: contain;
    will-change: transform;
  }
  
  .bird1 {
    top: 10%;
    left: -10%;
    animation: fly 20s linear infinite;
  }
  
  .bird2 {
    top: 30%;
    left: -20%;
    animation: fly 22s linear infinite;
  }
  
  .bird3 {
    top: 50%;
    left: -30%;
    animation: fly 18s linear infinite;
  }
  
  @keyframes fly {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(120vw);
    }
  }

  @media screen and (max-width: 730px) {

    @keyframes fly {
        0% {
            transform: translateX(0vw);
        }
        100% {
            transform: translateX(90vw);
        }
    }
}

@media screen and (max-width: 830px) {

  @keyframes fly {
      0% {
          transform: translateX(0vw);
      }
      100% {
          transform: translateX(81vw);
      }
  }
}
