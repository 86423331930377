@import url("https://fonts.googleapis.com/css?family=Luckiest+Guy&display=swap");

html {
    background: radial-gradient(circle, #fffc00 0%, #f0ed17 100%);
}

body {
    font-family: "Luckiest Guy", cursive;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1 {
    margin: 0;
    font-size: 3em; /* Ajusta este tamaño si es necesario */
    padding: 0;
    color: white;
    text-shadow: 0 0.1em 20px rgba(0, 0, 0, 1), 0.05em -0.03em 0 rgba(0, 0, 0, 1),
        0.05em 0.005em 0 rgba(0, 0, 0, 1), 0em 0.08em 0 rgba(0, 0, 0, 1),
        0.05em 0.08em 0 rgba(0, 0, 0, 1), 0px -0.03em 0 rgba(0, 0, 0, 1),
        -0.03em -0.03em 0 rgba(0, 0, 0, 1), -0.03em 0.08em 0 rgba(0, 0, 0, 1), -0.03em 0 0 rgba(0, 0, 0, 1);
}

span {
    transform: scale(0.9);
    display: inline-block;
}

span:first-child {
    animation: bop 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
}

span:last-child {
    animation: bopB 1s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
}

@keyframes bop {
    0% {
        transform: scale(0.9);
    }

    50%,
    100% {
        transform: scale(1);
    }
}

@keyframes bopB {
    0% {
        transform: scale(0.9);
    }

    80%,
    100% {
        transform: scale(1) rotateZ(-3deg);
    }
}

/* Media Queries for Mobile Responsiveness */
@media screen and (max-width: 425px), screen and (max-height: 800px) {
    body {
        font-size: 90%; /* Ajusta el tamaño de la fuente del cuerpo si es necesario */
    }

    h1 {
        font-size: 2em; /* Ajusta el tamaño del texto del título */
    }

    span {
        transform: scale(0.8); /* Ajusta el tamaño de la animación de escala */
    }

    span:first-child, span:last-child {
        animation: bop 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
    }
}

/* Media Query for very small screens */
@media screen and (max-width: 375px), screen and (max-height: 667px) {
    body {
        font-size: 80%; /* Ajusta aún más el tamaño de la fuente para pantallas muy pequeñas */
    }

    h1 {
        font-size: 1.5em; /* Ajusta el tamaño del texto del título para pantallas muy pequeñas */
    }

    span {
        transform: scale(0.7); /* Ajusta el tamaño de la animación de escala */
    }

    span:first-child, span:last-child {
        animation: bop 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards infinite alternate;
    }
}