/* Estilos generales */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#previous-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#outer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

img {
  max-width: 100%;
  height: auto;
}

/* Media Queries */
@media screen and (max-width: 425px) {
  #previous-content {
    padding: 10px;
  }

  #outer-box {
    padding: 10px;
  }

  img {
    width: 40px;
    margin: 5px;
  }

  .cloud {
    width: 120px;
    height: 40px;
  }

  .bird {
    width: 30px;
    height: 30px;
  }
}