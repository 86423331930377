body {
    font-family: sans-serif;
  }
  
  #outer-box {
    height: 100vh;
    background: linear-gradient(180deg, #f14, #ff8300) fixed;
    color: white;
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 32px;
  }
  
  #outer-box-two {
    height: 100vh;
    background: #f26705;
    color: white;
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 32px;
  }

  #outer-box-three {
    background: #efeb03;
    color: white;
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 24px 32px;
  }
  
  #previous-content {
    position: static; 
    background: #173a80;
    font-size: large;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 24px;
    text-align: center;
    align-content: space-between;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  